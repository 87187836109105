import { Link } from 'react-router-dom';

import { currencyFormat } from "../Assets/Variables";

const Item = ({id, nombre, largo, alto, ancho, diametro, precio, seccion, categoria}) => {
        return(
        <div className="obras-card" key={id}>
            <div className="img" style ={ {backgroundImage: `url('../../../img/obras/${id}.webp')` } } alt={nombre}></div>
            <div className="info">
                <h4>
                    {nombre}
                </h4>
                {
                    largo || ancho || alto || diametro !== undefined
                    ?
                    <article>
                    { largo === undefined
                        ? 
                        <div style={{display:"none"}}>
                        </div>  
                        : <div>Largo: {largo}cm<br/></div>
                    }
                    { alto === undefined
                        ? 
                        <div style={{display:"none"}}>
                        </div>  
                        : <div>Alto: {alto}cm<br/></div>
                    }
                    { ancho === undefined
                        ? 
                        <div style={{display:"none"}}>
                        </div>  
                        : <div>Ancho: {ancho}cm<br/></div>
                    }
                    { diametro === undefined
                        ? 
                        <div style={{display:"none"}}>
                        </div>  
                        : <div>Diámetro: {diametro}cm<br/></div>
                    }
                </article>
                    :
                    
                    <div style={{display:"none"}}>
                    </div> 
                }
                
                { precio === undefined
                    ? 
                    <div style={{display:"none"}}>
                    </div>  
                    : <div className="obras-precio">
                        <span>Precio: {currencyFormat(precio)}</span>
                    </div>
                }
                <Link to={`/${seccion}/${categoria}/${id}`} className="btnN" >VER +</Link>                
            </div>
        </div>
    )
}

export default Item