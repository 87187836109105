import React, { useLayoutEffect } from "react";

import ItemDetail from "../ItemDetail/ItemDetail";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";
import Banner from "../Banners/Banner";

import { useParams } from 'react-router-dom';
import { getProduct } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';
import ContactoForm from "../ContactoForm/ContactoForm";

const ItemDetailContainer = () => {

    const { productId } = useParams()
    const { isLoading, data, error } = useFirestore(() => getProduct(productId))

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    if(error) {
        return <NotA404 />
    }   
    
    return (
        <section className="item-detail-container">
            <Banner />
            {
                isLoading
                ? <Loading />
                : <ItemDetail {...data}/>
            }
            <ContactoForm />
        </section>
    )
}

export default ItemDetailContainer