import React from "react";

const Intro = () => {

    return(
        <section>
            <div className="intro">
                <article>
                    Inspirados en técnicas primitivas, creamos objetos escultóricos funcionales. Esculpimos la madera con fuego, generando piezas orgánicas y cada una de ellas es única ya que el fuego nunca quema de la misma forma.
                </article>
            </div>
        </section>
    )
}

export default Intro