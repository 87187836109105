import Item from '../Item/Item';

const ItemList = ({products}) => {

    const urlitemlist = window.location.pathname.split("/")

    return(
        <div className='item-list'>
        { urlitemlist[1] === "obras"
            ?
            <div className='container-cards'>
                {products.filter(product => product.activo === "si" && product.seccion === "obras")
                .map(prod => <Item key={prod.id} {...prod} />)}
            </div>
            : 
            <div className='container-cards'>
                {products.filter(product => product.activo === "si" && product.seccion === "primitiva")
                .map(prod => <Item key={prod.id} {...prod} />)}
            </div>
        }
        </div>
    )
}

export default ItemList