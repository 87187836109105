import React from "react";

const BannerHome = () => {

    return(
        <section>
            <div className="banner-home" style ={ {backgroundImage: `url('../img/banner-home.png')` } }>
                <img src="../../img/logoB.webp" alt="PRIMITIVA" className="logo"/>
            </div>
        </section>
    )
}

export default BannerHome