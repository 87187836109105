import React, { useLayoutEffect } from "react";
import ItemList from "../ItemList/ItemList";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";

import Banner from "../Banners/Banner";
import ContactoForm from "../ContactoForm/ContactoForm";

import { useParams } from 'react-router-dom';
import { getProducts } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const Obras = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const { categoryId } = useParams()
    const { isLoading, data, error } = useFirestore(() => getProducts(categoryId), [categoryId])

    if(error) {
        return <NotA404 />
    }
    
    return(
        <section>
            <Banner />
            <div className="obras">
                <h2>OBRAS</h2>
                <article>
                    Esta serie de obras son el resultado de un proyecto que se desarrolló en La Pampa y La Patagonia Argentina, donde se siguió el rastro de los incendios forestales. Cada una tiene una historia detrás…
                </article>
                {
                    isLoading
                    ? <Loading />
                    : <ItemList products={data} />
                }
            </div>
            <ContactoForm />
        </section>
    )
}

export default Obras