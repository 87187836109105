import React, { useEffect } from 'react'
import Aos from "aos";

import "aos/dist/aos.css";

const PreContact = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-in", once: true})
    }, [])

    return (
        <section>
            <article data-aos="fade">
                Recorremos los campos en búsqueda de árboles caídos, recuperando y aprovechando esa madera que la naturaleza nos ofrece. Y en recompensa, plantamos árboles por cada una de las piezas que realizamos.
            </article>
        </section>
    )
}

export default PreContact