import React, { useLayoutEffect, useEffect } from "react";

import Banner from "../Banners/Banner";
import ContactoForm from "../ContactoForm/ContactoForm";

import { getCapitulos } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore'

import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";

import Aos from "aos";

import "aos/dist/aos.css";

const Pyro = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-in", once: true})
    }, [])

    const { isLoading, data, error  } = useFirestore(() => getCapitulos())

    if(error) {
        return <NotA404 />
    }
    
    return(
        <section>
            <Banner />
            <div className="pyro">
                <h2>PYRO</h2>
                <span>Mini serie</span>
                <article>
                    Este documental es el resultado de un proyecto que se desarrolló en La Pampa y La Patagonia en 2017, donde se siguió el rastro de los incendios forestales en busca de lo que dejan detrás. 
                    <br/><br/>
                    Metido en el incendio, hipnotizado por su luz, su calor y su poder, imagino el fuego como un Dios al que llame “Pyro”, que consume y destruye todo a su paso. 
                    <br/><br/>
                    Las obras registran ese instante dentro de la batalla en la que pierden ambos, porque el árbol muere y el fuego se apaga: dejando en evidencia el contraste entre las lineas rectas del hombre con las formas orgánicas de la naturaleza.
                </article>
                <div className="pyro-container">
                    { isLoading
                    ? <Loading />
                    :
                    data.sort( 
                        (a,b) => a.id > b.id ? 1 : -1
                    ).map(prod => {
                        return(
                            <div className="pyro-card" key={prod.id}>
                                <img src={`../img/pyro/${prod.img}`} alt={prod.titulo} data-aos="fade"/>
                                <h3>{prod.capitulo}</h3>
                                <span>{prod.titulo}</span>
                                <a href={prod.link} target="_blank" className="btnN">VER</a>
                            </div>
                        )})
                    }
                </div>
            </div>
            <ContactoForm />
        </section>
    )
}

export default Pyro