import React, { useLayoutEffect } from "react";

import ContactoForm from "../ContactoForm/ContactoForm";
import { LinkMail, LinkInstagram, LinkWhatsApp } from '../Assets/Variables';

const Contacto = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return(
        <section>
            <div className="banner-contacto">
                <img src="../../img/logoB.webp" alt="PRIMITIVA" className="logo"/>
            </div>
            <div className="contacto-info">
                <h2>CONTACTO</h2>
                <article>
                    Desarrollamos productos según la necesidad de cada cliente.
                    <br/><br/>
                    Contanos en qué estás pensando, cuál es tu idea y cómo es tu espacio, en base a eso podemos crear una obra a medida.
                    <br/><br/>
                    MAIL
                    <br/>
                    {LinkMail}
                    <br/><br/>
                    INSTAGRAM
                    <br/>
                    {LinkInstagram}
                    <br/><br/>
                    WHATSAPP
                    <br/>
                    {LinkWhatsApp}
                </article>
            </div>
            <ContactoForm />
        </section>
    )
}

export default Contacto