import { db } from ".";
import { getDocs, collection, query, where, getDoc, doc } from 'firebase/firestore'

export const getCapitulos = (capitulosId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = capitulosId 
            ? query(collection(db, 'capitulos'), where('id', '==', capitulosId)) 
            : collection(db, 'capitulos')

            getDocs(collectionRef).then(response => {
                const capitulos = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(capitulos)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getProducts = (categoryId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = categoryId 
            ? query(collection(db, 'obras'), where('categoria', '==', categoryId)) 
            : collection(db, 'obras')

            getDocs(collectionRef).then(response => {
                const products = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(products)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getProduct = (productId) => {
    return new Promise((resolve, reject) => {
            getDoc(doc(db, 'obras', productId)).then(response => {
                const product = { id: response.id, ...response.data()}
                resolve(product)
            }).catch(error => {
                reject(error)
            })
    })
} 