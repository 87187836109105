import React from "react";

import './styles.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Contacto from './components/Contacto/Contacto';
import Primitiva from "./components/Primitiva/Primitiva";
import Pyro from "./components/Pyro/Pyro";
import Obras from "./components/Obras/Obras";
import NotA404 from "./components/Assets/NotA404";
import ItemDetailContainer from "./components/ItemDetailContainer/ItemDetailContainer";

const App = () => {

  return (
    <div className='appContainer'>
      <BrowserRouter>
          <header>
            <NavBar />
          </header>
          <main>
            <Routes>
              <Route path='/' element={ <Home /> }/>
              <Route path="/contacto" element={ <Contacto /> }/>
              <Route path="/primitiva/:categoryId" element={ <Primitiva /> }/>
              <Route path='/primitiva/:categoryId/:productId' element={ <ItemDetailContainer /> } />
              <Route path="/obras" element={ <Obras /> }/>
              <Route path='/obras/:categoriaId/:productId' element={ <ItemDetailContainer /> } />
              <Route path="/pyro" element={ <Pyro /> }/>
              <Route path='*' element={ <NotA404 /> }/>
            </Routes>
          </main>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;