import React from "react";
import { Link } from "react-router-dom";

const Pyro = () => {

    return(
        <section>
            <div className="pyro-home"  style ={ {backgroundImage: "url('./img/banner-pyro-home.webp')" } }>
                <div className="container" >
                    <h3>
                        Mini serie
                    </h3>
                    <h2>
                        PYRO
                    </h2>
                    <Link to='/pyro' className="btnB" >VER</Link>
                </div>
            </div>
        </section>
    )
}

export default Pyro