import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC6KZfRoWLuSBVNkWcypUW6fi4Uo7x-dZw",
    authDomain: "primitiva-45b6f.firebaseapp.com",
    projectId: "primitiva-45b6f",
    storageBucket: "primitiva-45b6f.appspot.com",
    messagingSenderId: "583700134384",
    appId: "1:583700134384:web:51e00b7e9f99ecd4240535"
  };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

