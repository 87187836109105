import React from "react";
import { Link } from "react-router-dom";

import { getProducts } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore'

import { currencyFormat } from "../../Assets/Variables";
import Loading from "../../Assets/Loading";
import NotA404 from "../../Assets/NotA404";

const Obras = () => {

    const { isLoading, data, error  } = useFirestore(() => getProducts())

    if(isLoading) {
        return <Loading />
    }

    if(error) {
        return <NotA404 />
    }
    
    return(
        <section>
            <div className="obras-home">
                <div className="container-cards">
                    {data.filter(product => product.activo === "si" && product.home === "si")
                        .sort((a,b) => a.ordenHome > b.ordenHome ? 1 : -1)
                        .map(prod => {
                            return(

                                <div className="obras-card" key={prod.id}>
                                    <div className="img" style ={ {backgroundImage: `url('../img/obras/${prod.id}.webp')` } }></div>
                                    <div className="info">
                                        <h4>
                                            {prod.nombre}
                                        </h4>
                                        <article>
                                            { prod.largo === undefined
                                                ? 
                                                <div style={{display:"block"}}>
                                                </div>  
                                                : <div>Largo: {prod.largo}cm<br/></div>
                                            }
                                            { prod.alto === undefined
                                                ? 
                                                <div style={{display:"block"}}>
                                                </div>  
                                                : <div>Alto: {prod.alto}cm<br/></div>
                                            }
                                            { prod.ancho === undefined
                                                ? 
                                                <div style={{display:"block"}}>
                                                </div>  
                                                : <div>Ancho: {prod.ancho}cm<br/></div>
                                            }
                                            { prod.diametro === undefined
                                                ? 
                                                <div style={{display:"block"}}>
                                                </div>  
                                                : <div>Diámetro: {prod.diametro}cm<br/></div>
                                            }
                                        </article>
                                        { prod.precio === undefined
                                            ? 
                                            <div style={{display:"block"}}>
                                            </div>  
                                            : <div className="obras-precio">
                                                <span>Precio: {currencyFormat(prod.precio)}</span>
                                            </div>
                                        }
                                        <Link to={`${prod.seccion}/${prod.categoria}/${prod.id}`} className="btnN" >VER +</Link>
                                    </div>
                                </div>
                            )})
                        }
                </div>
                <Link to='/primitiva/en-serie' className="btnN" >VER TODAS</Link>
            </div>
        </section>
    )
}

export default Obras