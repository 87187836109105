import React from "react";
import ReactWhatsapp from 'react-whatsapp';
import { useParams } from 'react-router-dom';
import { getProduct } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

const ContactDetail = () => {

    const { productId } = useParams()
    const { data } = useFirestore(() => getProduct(productId))

    return(
        <div className="contact-detail">
            <ReactWhatsapp number="5491138125402" message={`¡Hola! Te consulto por ${data.nombre}. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                WHATSAPP
            </ReactWhatsapp>
            <a href={`mailto:primitiva.blaquier@gmail.com?subject=¡Hola PRIMITIVA!&body=¡Hola! Te consulto por ${data.nombre}. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                MAIL
            </a>
        </div>
    )
}

export default ContactDetail