import React, { useLayoutEffect } from "react";
import ItemList from "../ItemList/ItemList";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";

import Banner from "../Banners/Banner";
import ContactoForm from "../ContactoForm/ContactoForm";

import { NavLink, useParams } from 'react-router-dom';
import { getProducts } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const Primitiva = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const urlprimitiva = window.location.pathname.split("/")

    const { categoryId } = useParams()
    const { isLoading, data, error } = useFirestore(() => getProducts(categoryId), [categoryId])

    if(error) {
        return <NotA404 />
    }
    
    return(
        <section>
            <Banner />
            <div className="primitiva">
                <img className="logo" src="../../img/logoN.webp"/>
                <article>
                    {
                        urlprimitiva[2] === "especiales"
                        ?   <div>
                                Desarrollamos piezas segun la necesidad de cada cliente, salimos a buscar la madera adecuada para hacerlas.
                                <br/><br/>
                                Contános cual es tu idea, cómo es tu espacio, y en base a eso podemos crear una obra a medida.
                            </div>
                        : 
                            <div>
                                Nuestras piezas en serie se hacen por encargo, consultanos por medidas y precios.
                            </div>
                        
                    }
                    {/* El fuego nunca quema igual ya que depende de variables como el viento, el calor, el tiempo y otros factores que afectan la forma. Por eso cada una de nuestras piezas son únicas. */}
                </article>
                <div className="linkCategorias">
                    {/* <NavLink to='/primitiva' className={({isActive}) => isActive ? "activeLinks" : "links"} >VER TODO</NavLink> */}
                    <NavLink to='/primitiva/en-serie' className={({isActive}) => isActive ? "activeLinks" : "links"} >EN SERIE</NavLink>
                    <NavLink to='/primitiva/especiales' className={({isActive}) => isActive ? "activeLinks" : "links"} >ESPECIALES</NavLink>
                </div>
                {
                    isLoading
                    ? <Loading />
                    : <ItemList products={data} />
                }
            </div>
            <ContactoForm />
        </section>
    )
}

export default Primitiva