import React from "react";

import { LinkHome, LinkPrimitiva, LinkObras, LinkPyro, LinkContacto, LinkInstagramIcon, LinkWhatsAppIcon } from '../Assets/Variables';


const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="container-links">
                    <div className="links">
                        {LinkHome}
                        {LinkPrimitiva}
                        {LinkObras}
                        {LinkPyro}
                        {LinkContacto}
                    </div>
                    <div className="redes">
                        {LinkInstagramIcon}
                        {LinkWhatsAppIcon}
                    </div>
                </div>
                <div className="container-logo">
                    <img src="../../img/logoN.webp" alt="PRIMITIVA" className="logo"/>
                </div>
            </div>
        </footer>
    )
}

export default Footer