import React from "react";

const Banner = () => {

    const url = window.location.pathname.split("/")
    
    return(
        <div className="banner-section" style ={ {backgroundImage: `url('../../img/banner-${url[1]}.png')` } }>
        </div>
    )
}

export default Banner