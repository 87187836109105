import React, { useState } from "react";
import { Link } from "react-router-dom";
import { currencyFormat } from "../Assets/Variables";
import ContactDetail from "./ContactDetail";


const ItemDetail = ({id, nombre, descripcion, alto, largo, ancho, diametro, precio, seccion, categoria }) =>{

    const [isClicked, setIsClicked] = useState(false);

    const urlitem = window.location.pathname.split("/")

    return(
        <div className="item-detail">
            <h2>{nombre}</h2>
            <div className="container-item-detail">
                <div className="img" style ={ {backgroundImage: `url('../../../img/obras/${id}.webp')` } } alt={nombre}></div>
                {
                    largo || ancho || alto || diametro || precio !== undefined
                    ?
                    <div className="info">
                    <article>
                        { descripcion === undefined
                            ? 
                            <div style={{display:"none"}}>
                            </div>  
                            : <div>{descripcion}</div>
                        }
                        <br/><br/>
                        { largo === undefined
                            ? 
                            <div style={{display:"none"}}>
                            </div>  
                            : <div><span>Largo:</span> {largo}cm<br/></div>
                        }
                        { alto === undefined
                            ? 
                            <div style={{display:"none"}}>
                            </div>  
                            : <div><span>Alto:</span> {alto}cm<br/></div>
                        }
                        { ancho === undefined
                            ? 
                            <div style={{display:"none"}}>
                            </div>  
                            : <div><span>Ancho:</span> {largo}cm<br/></div>
                        }
                        { diametro === undefined
                            ? 
                            <div style={{display:"none"}}>
                            </div>  
                            : <div><span>Diámetro:</span> {diametro}cm<br/></div>
                        }
                    </article>
                    { precio === undefined
                        ? <div style={{display:"none"}}></div> 
                        : <div className="obra-precio">
                            <span>Precio: {currencyFormat(precio)}</span>
                        </div>
                    }
                </div>
                    :
                    
                    <div style={{display:"none"}}>
                    </div> 
                }
                
                
            </div>
            <div className="contactar-container">
                {
                    <div onClick={() => setIsClicked(!isClicked)}>
                        {isClicked
                        ? <ContactDetail />
                        : <button className="btnN">CONTACTAR</button>
                        }
                    </div>
                }
                {
                    urlitem[1] !== "obras"
                    ? <Link to={"/"+`${seccion}`+"/"+`${categoria}`} className="btnNSB" >VOLVER</Link>
                    : <Link to={"/"+`${seccion}`} className="btnNSB" >VOLVER</Link>
                }
            </div>
        </div>
    )
}

export default ItemDetail