import React, { useLayoutEffect } from "react";
import BannerHome from "./Banners/BannerHome";
import Intro from "./Intro/Intro";
import ContactoForm from "../ContactoForm/ContactoForm";

import "aos/dist/aos.css";
import Pyro from "./Pyro/Pyro";
import Esfera from "./Esfera/Esfera";
import PreContact from "./PreContact/PreContact";
import Obras from "./Obras/Obras";

const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return(
        <div>
            <BannerHome />
            <Intro />
            <Obras />
            <Pyro />
            <Esfera />
            <PreContact />
            <ContactoForm />
        </div>
    )
}

export default Home