import React, { useEffect } from 'react'
import Aos from "aos";

import "aos/dist/aos.css";

const Esfera = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-in", once: true})
    }, [])

    return (
        <section>
            <div className='esfera'>
                <div className="esfera-home" style ={ {backgroundImage: "url('./img/esfera-home.webp')" } }>
                </div>
                <div className='esfera-info'>
                    <article data-aos="fade">
                        Este documental es el resultado de un proyecto que se desarrolló en La Pampa y La Patagonia en 2017, donde se siguió el rastro de los incendios forestales en busca de lo que dejan detrás. 
                    </article>
                </div>
            </div>
        </section>
    )
}

export default Esfera